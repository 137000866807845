import 'babel-polyfill'
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill()
Es6Promise.polyfill()

import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store'
// import ElementUI from 'element-ui';
Vue.config.silent = true//关闭[Vue warn] ，兼容IE内核
import 'normalize.css/normalize.css' // A modern alternative to CSS resets
// import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';

import 'amfe-flexible';//px转换rem
Vue.use(Vant);

import axios from 'axios'
window.eventBus = new Vue();  //注册bus
// axios.defaults.baseURL = "http://192.168.1.50:8000"

Vue.prototype.$http = axios

//签名
import SignCanvas from "sign-canvas";

Vue.use(SignCanvas);



import { mixin } from './utils/mixin'
Vue.mixin(mixin)

import global_ from './base.vue'

Vue.prototype.GLOBAL = global_

import moment from 'moment'
Vue.prototype.$moment = moment

import  Meta from 'vue-meta';
Vue.use(Meta);  //动态添加meta


import wechatTitle from 'vue-wechat-title'
Vue.use(wechatTitle) //标题切换
// 判断是不是手机端微信或者电脑端微信浏览器
if (/(micromessenger)/i.test(navigator.userAgent)) {
  if(/(WindowsWechat)/i.test(navigator.userAgent) || /(wechatdevtools)/i.test(navigator.userAgent)){
    //电脑微信或者开发者工具
    Vue.prototype.isWechat = false
  }else{
    //手机微信打开的浏览器
    axios.post('/index.php/wechat/isHaveAppId').then(res=>{
      if(res.data.code == 0){
        Vue.prototype.isWechat = true
      }else{
        Vue.prototype.isWechat = false
      }
    })
  }
 
} else {
  Vue.prototype.isWechat = false
}

//平台、设备和操作系统  
// var system = { 
//   win: false, 
//   mac: false, 
//   xll: false, 
//   ipad: false 
// }; 
// //检测平台  
// var p = navigator.platform; 
// alert(p)

// system.win = p.indexOf("Win") == 0; 
// system.mac = p.indexOf("Mac") == 0; 
// system.x11 = (p == "X11") || (p.indexOf("Linux") == 0); 
// system.ipad = (navigator.userAgent.match(/iPad/i) != null) ? true : false; 
// //跳转语句，如果是手机访问就自动跳转到wap.baidu.com页面  
// if (system.win || system.mac || system.xll || system.ipad) { 
//   alert("在PC端上打开的");
// } else { 
//   var ua = navigator.userAgent.toLowerCase();  
//   if(ua.match(/MicroMessenger/i)=="micromessenger") {  
//     alert("在手机端微信上打开的"); 
//   } else {  
//     alert("在手机上非微信上打开的"); 
//   }  
// }


/**
 *   //Mozilla/5.0 (Windows NT 6.1; WOW64)
 *  AppleWebKit/537.36 (KHTML, like Gecko) Chrome/81.0.4044.138 Safari/537.36 NetType/WIFI MicroMessenger/7.0.20.1781(0x6700143B) WindowsWechat(0x6307001d)

    //Mozilla/5.0 
    (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 
    (KHTML, like Gecko) Version/11.0 Mobile/15A372 Safari/604.1 
    wechatdevtools/1.05.2204250 MicroMessenger/8.0.5 Language/zh_CN webview/1655979598380247 webdebugger port/58618 token/f5c3c5683f7b27d56e57c6c321ec794b

    //Mozilla/5.0 
    (iPhone; CPU iPhone OS 15_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 MicroMessenger/8.0.23(0x1800172a) NetType/WIFI Language/zh_CN
 * 
 * 
 * 
 */


 new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
