<script>
// const BASE_URL="http://192.168.1.50:8000"
// const BASE_URL="http://192.168.1.80:8888"//测试
const BASE_URL=""//测试
// const BASE_URL="http://192.168.1.187:8080/"//准生产
// const BASE_URL="http://ccj.chexd.com/"//生产

export default{
    BASE_URL
}
</script>
