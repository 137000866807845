import {deleteCookie} from './env'
import { Toast } from 'vant';
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import wx from 'weixin-js-sdk'
import Bus from './bus'
export const mixin = {
    methods:{
        islogout(){
            this.$http.post('/index.php/index/login_Out').then(res=>{
            deleteCookie('token')
            this.$toast.success(res.data.msg)
            this.$router.push('/')
            window.localStorage.clear();
            // this.$http.post('/index.php/index/wechat.wechat/domain').then(res=>{
            //         if(res.data.code == 0){
            //             let domain = this.zhuan(res.data.data)
            //             let url = window.location.origin
            //             window.location.href="https://wechat.chexd.com/chaichejian/BeDefeated.html?domain=" +domain + '&url=' +url
            //         }
            //     })
            })
        },
        
        async recognition(url,imgUrl,key){
            const Istoast = Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: '加载中...',
            });
            try{
                let data = encipherMent( JSON.stringify(imgUrl))
                let param = new URLSearchParams();
                param.append("value",data);
              
                const res =  await this.$http.post(url,param).then(res=>res)
                let result = {}
                // Istoast.clear()//清除加载效果
                if(res.data.code == -1 || res.data.code == 9){
                    result = res.data
                }else{
                    result = JSON.parse( encryptDecode(res.data))
                }  
                
                if(result.code == 0 ){
                // console.log(Istoast)
                    Istoast.clear()//清除加载效果
 
                    if(key == 'verso'){//行驶证反面
                        this.versoData = result.data
                        if(this.idsIndex == 9) Bus.$emit('versoDataInfo', result.data);
                    }else if(key == 'vin'){//Vin
                        let stopMusic = window.localStorage.getItem('stopMusic') || false;
                        if(stopMusic == 'true'){
                            this.isVin(result.data)
                        }
                        this.vindata = result.data
                        
                    }else if(key == 'shibvin'){//识别Vin
                        this.form.vin = result.data
                    }else if(key == 'bankZ'){//识别Vin
                       
                        this.isData = result.data
                    }else if(key == 'bankF'){//识别Vin
                        
                        this.isData = result.data
                        
                       
                    }else{
                        let stopMusic = window.localStorage.getItem('stopMusic') || false;
                        if(stopMusic == 'true'){
                            this.isPlay(result.data)
                        }
                        if(this.idsIndex == 9){
                            if(key == 'car'){
                                // this.carInfo = result.data
                                this.carData = result.data
                                Bus.$emit('carDataInfo', result.data);
                                
                            }else if(key == 1){
                                Bus.$emit('frontUrlInfo', result.data);
                                
                            }
                        }else{

                            this.isData = result.data
                        }

                        console.log(this.isData,'识别')
                    }
                }else if(result.code == -1){
                    this.islogout()
                }else{
                    
                    Istoast.clear()//清除加载效果
                    // this.$toast.fail(result.msg)

                    if(key == 'vin'){
                        this.vindata = ''
                    }else if(key == 'shibvin'){
                        this.form.vin = ''
                    }else if(key == 'verso'){
                        this.versoData = ''
                    }else{
                        this.isData = {}
                    }
                }
            }
            catch (err){
              
                Istoast.clear()//清除加载效果
                // if(key){
                //     this.fileList2=[]
                //     this.reverseUrl = ''
                // }else{
                //     this.fileList1=[]

                // }
                this.$toast.fail('加载失败，请重新尝试！')
            }
        },
        //强制保留两位小数，不足补零
        returnFloat(num) {
            let decimal = 2
            num = num.toString()
            const index = num.indexOf('.')
            if (index !== -1) {
                num = num.substring(0, decimal + index + 1)
            } else {
                num = num.substring(0)
            }
                //截取后保留两位小数
            return parseFloat(num).toFixed(decimal)
        },
        //获取wcConfig
        async wcConfig() {
            let ua = navigator.userAgent;
            let isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            let url = isiOS ? window.location.origin + '/' : window.location.href
            try{
         
                const {data} = await this.$http.post('/index.php/wechat/getWechatJSSignPackage',{ appId: '1',url:url}).then(res=>res)
                if(data.code == 0){
                  
                    let signPackage = data.signPackage
                    wx.config({ //  通过后端
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
                        appId: signPackage.appId, // 必填，公众号的唯一标识
                        timestamp: signPackage.timestamp, // 必填，生成签名的时间戳
                        nonceStr: signPackage.nonceStr, // 必填，生成签名的随机串
                        signature: signPackage.signature, // 必填，签名，见附录1
                        jsApiList: ['chooseImage','previewImage','uploadImage','downloadImage','getLocalImgData'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
                    }) ;
                }
            }
            catch{
                console.log('请求错误')
            }
                
           
			
		},
        //微信上传图片
        WeChooseImage(num,name,index){
            this.islable = name
            this.idsIndex = index
            // this.wcConfig()
          
            var this_ = this
            wx.ready(function() { // 需在用户可能点击分享按钮前就先调用
                wx.chooseImage({
                    count: num, // 默认9
                    sizeType: [ 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
                    sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
                    success: function (res) {
                    var localIds = res.localIds; // 返回选定照片的本地 ID 列表，localId可以作为 img 标签的 src 属性显示图片
                 
                    if(localIds.length!=0){
                           
                            this_.wximagelocalids = localIds
                           
                        }
                    }
                });
            })
            
        },
         wxUpload(localId){
           var this_ = this
           
           wx.ready(function() { // 需在用户可能点击分享按钮前就先调用
                wx.uploadImage({
                    localId: localId, // 需要上传的图片的本地ID，由 chooseImage 接口获得
                    isShowProgressTips: 1, // 默认为1，显示进度提示
                    success: async function (res) {
                    var serverId = res.serverId; // 返回图片的服务器端ID
                        try{ 
                            const {data} = await this_.$http.post("/index.php/index/upload/index", {media_id:serverId}).then(res=>res)
                           
                            if(data.code == 0){
                              
                                if(this_.islable == '45Degrees'){//45°照
                                    if(this_.idsIndex == 9){
                                        this_.path = data.data
                                    }else{
                                        let url = 'index.php/vehicles_upload/vehicleCarNumberPlate'
                                        this_.imageUrl = data.data
                                        this_.recognition(url,{path:data.data})
                                    }
                                }else if(this_.islable == 'Vin'){//VIN
                                    if(this_.idsIndex == 9){
                                        this_.vin_photos = data.data
                                    }else{
                                        let url = '/index.php/index/bid_management.bid_order/apiBaiDu'
                                        this_.VinUrl = data.data
                                        this_.recognition(url,{url:data.data},'vin')
                                    }
                                }else if(this_.islable == 'shibVin'){//VIN
                                    let url = '/index.php/index/bid_management.bid_order/apiBaiDu'
                                    // this_.VinUrl = data.data
                                    this_.recognition(url,{url:data.data},'shibvin')
                         
                                }else if(this_.islable == 'front'){//行驶证正面

                                    let url = '/index.php/index/Image_Recognition/vehicleLicenseFront'
                                    this_.frontUrl = data.data
                                    this_.recognition(url,{path:data.data},1)
                                }else if(this_.islable == 'reverse'){//行驶证反面
                                    let url = '/index.php/index/Image_Recognition/vehicleLicenseBack'
                                    this_.reverseUrl = data.data
                                    this_.recognition(url,{path:data.data},'verso')
                                  
                                }else if(this_.islable == 'carInfo'){//证件照   身份证/营业执照

                                    let url = '/index.php/vehicles_upload/idcardBusinessLicense'
                                    this_.recognition(url,{path:data.data},'car')

                                    if(this_.idsIndex == 9){
                                        this_.carInfo_photos = data.data
                                      
                                    }else{
                                        this_.imageUrl = data.data
                                    }
                                }else if(this_.islable == 'hui'){//毁形照
                                   
                                    if(this_.idsIndex == 9){
                                        this_.destruction_photos = data.data
                                    }else{
                                        this_.imageHui = data.data
                                    }
                                }else if(this_.islable == 'ciagou'){//毁形照
                                    if(this_.WXimg.length > 5 ){
                                        alert('最多上传6张照片')
                                    }else{
                                        this_.WXimg.push(data.data)//没有特定规定的照片  走这个数组进行循环渲染
                                    }
                                }else if(this_.islable == 'tuoyun'){//毁形照
                                    if(this_.WXimg.length > 2 ){
                                        alert('最多上传3张照片')
                                    }else{
                                        this_.WXimg.push(data.data)//没有特定规定的照片  走这个数组进行循环渲染
                                    }
                                
                                }else if(this_.islable == 'bigCar'){//大车
                                    this_.from.image = data.data
                                }else if(this_.islable == 'xiaohu'){//销户
                                    if(this_.idsIndex == 9){
                                        this_.cancellation_photos = data.data
                                    }else{
                                        this_.proveUrl = data.data
                                        let url = '/index.php/vehicles_upload/cancellationPhotos'
                                        this_.recognition(url,{path:data.data})
                                    }
                                    // this_.recognition(url,{path:'http://cheqidian.oss-cn-beijing.aliyuncs.com/CJ/20220620/1070/128/6aee1e543d647dcf1965f096d3ef96e3.jpg'})
                                }else if(this_.islable == 'top_top'){//头部+车顶
                                    this_.top_top = data.data
                                }else if(this_.islable == 'left_top'){//左侧+车顶
                                    this_.left_top = data.data
                                }else if(this_.islable == 'right_top'){//右侧+车顶
                                    this_.right_top = data.data
                                }else if(this_.islable == 'button_top'){//下面+车顶
                                    this_.button_top = data.data
                                }else if(this_.islable == 'inside'){//机盖内部
                                    this_.inside = data.data
                                }else if(this_.islable == 'feature'){//三元
                                    this_.feature = data.data
                                }else if(this_.islable == 'huiyongPhoto'){//三元
                                    this_.infoObj[this_.idsIndex].img_boxs.push(data.data)//没有特定规定的照片  走这个数组进行循环渲染
                                }else if(this_.islable == 'duan'){//锻炼
                                    this_.break_beam_photos = data.data//没有特定规定的照片  走这个数组进行循环渲染
                                }else if(this_.islable == 'Fan'){//身份证反面
                                    this_.imageUrlF = data.data
                                }else if(this_.islable == 'shenZ'){//身份证正面
                                    this_.picker_id_positive = data.data
                                }else if(this_.islable == 'Pz'){//提车凭证原件照片：
                                    this_.image_script = data.data
                                }else if(this_.islable == 'tou45'){//提车现场拖车车头斜45度+提车人照片：
                                    this_.positive_car_image = data.data
                                }else if(this_.islable == 'wei45'){//提车现场车尾斜45度照片
                                    this_.back_car_image = data.data
                                }else if(this_.islable == 'deng'){//提车现场车尾斜45度照片
                                    this_.registratioCard = data.data
                                }else if(this_.islable == 'bankZ'){//银行卡正
                                    console.log(data.data)
                                    this_.bankZImg = data.data
                                }else if(this_.islable == 'bankF'){//银行卡反
                                    this_.bankFImg = data.data
                                }else if(this_.islable == 'imageFileArr'){//毁性照片留档
                                    this_.imageFileArr.push(data.data)
                                }else {
                                    this_.WXimg.push(data.data)//没有特定规定的照片  走这个数组进行循环渲染
                                }
                                this_.wximagelocalids.splice(this_.wximagelocalids[0],1)//上传完成之后删除当前这个
                            }else{
                                this_.$toast.error(data.msg)
                                this_.toast.clear();
                            }
                        }
                        catch{
                            this_.toast.clear();
                        }
                    }
                });
            })
            
               
        },
        //查看图片大图
        bigImg(url){
            var this_ = this
            wx.ready(function() { // 需在用户可能点击分享按钮前就先调用
                wx.previewImage({
                    current: url, // 当前显示图片的 http 链接
                    urls: [url] // 需要预览的图片 http 链接列表
                });
            })
        },
        //删除
        delImgHan(v,i){

            switch (v) {
                case 'arrPhoto':
                    this.WXimg.splice(i,1)
                    break;
                case 'imageFile':
                    this.imageFileArr.splice(i,1)
                    break;
                case 'huiyongPhoto':
                    this.infoObj[this.idsIndex].img_boxs.splice(i,1)
                    break;
                case '45Degrees':
                    this.imageUrl = ''
                    this.path = ''
                    break;
                case 'Vin':
                    this.VinUrl = ''
                    
                    this.vin_photos = ''
                    break;
                case 'front':
                    this.frontUrl = ''
                    break;
                case 'reverse':
                    this.reverseUrl = ''

                    break;
                case 'carInfo':
                    this.imageUrl = ''
                    
                    this.carInfo_photos = ''
                    break;
                case 'hui':
                    this.imageHui = ''
                    this.destruction_photos = ''
                    break;
                case 'bigCar':
                    this.from.image = ''
                    break;
                case 'xiaohu':
                    this.proveUrl = ''
                    this.cancellation_photos = ''
                    break;
                case 'top_top':
                    this.top_top = ''
                    break;
                case 'left_top':
                    this.left_top = ''
                    break;
                case 'right_top':
                    this.right_top = ''
                    break;
                case 'button_top':
                    this.button_top = ''
                    break;
                case 'inside':
                    this.inside = ''
                    break;
                case 'feature':
                    this.feature = ''
                    break;
                case 'duan':
                    this.break_beam_photos = ''
                    break;
                case 'Fan':
                    this.imageUrlF = ''
                    break;

                case 'shenZ':
                    this.picker_id_positive = ''
                    break;
                case 'Pz':
                    this.image_script = ''
                    break;
                case 'tou45':
                    this.positive_car_image = ''
                    break;
                case 'wei45':
                    this.back_car_image = ''
                    break;
                case 'deng':
                    this.registratioCard = ''
                    break;
                case 'bankZ':
                    this.bankZImg = ''
                    break;
                case 'bankF':
                    this.bankFImg = ''
                    break;
                default:
                    break;
            }
            
        },
        getMechanism(){
            let that=this;
            console.log("dismantle_allocation_type",that.dismantle_allocation_type)
            this.$http.post("/index.php/setUp/info",{id : 22}).then(res=>{
                if(res.data.code ==0){
                    let str = res.data.data.default
                    if(str == '预处理后自动拆解'){
                        this.dismantle_allocation_type = 1
                        this.allocation_type = str
                    }else if(str == '预处理后资料合格手动拆解'){
                        this.dismantle_allocation_type = 2
                        this.allocation_type = str
                    }else if(str == '预处理后资料合格自动拆解'){
                        this.dismantle_allocation_type=3
                        this.allocation_type = str
                    }
                }
                console.log("dismantle_allocation_type",that.dismantle_allocation_type)
            })
        },
    }
}